<template>
  <div class="flex flex-grow-0">
    <lf-pill v-if="status" class="uppercase mr-2" :color-index="colorIndex">
      {{ status }}
    </lf-pill>
    <div v-else>-</div>
  </div>
</template>
<script setup lang="ts">
import { computed } from "vue";
import { useStore } from "vuex";

const COLOR_CODES = {
  1: 0,
  2: 5,
  3: 2,
  4: 5,
  5: 2,
  6: 5,
  blank: ""
};

const props = defineProps({
  modelValue: {
    type: Number,
    default: null
  },
  showFundedInsteadOfAccepted: {
    type: Boolean,
    default: false
  }
});

const store = useStore();

const offerStatuses = computed<Record<string, string>>(
  () => store.getters["options/offerStatuses"]
);

const status = computed(() => {
  const value = props.modelValue ? offerStatuses.value[props.modelValue] : "";
  return props.showFundedInsteadOfAccepted && value === "Accepted"
    ? "Funded"
    : value;
});

const colorIndex = computed(
  () => COLOR_CODES[(props.modelValue as keyof typeof COLOR_CODES) ?? "blank"]
);
</script>
